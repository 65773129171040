<template>
  <svg :id="'chart' + instanceId" class="w-full h-full text-green"></svg>
</template>

<script>
import { watchEffect, getCurrentInstance, onMounted } from "vue";
import { isMobile } from "@/global/services/mixins";
import * as d3 from "d3";

import { useI18n } from "vue-i18n";

export default {
  name: "Chart",
  props: {
    xData: Array,
    yData: Array
  },
  setup(props) {
    const { t } = useI18n({
      messages: {
        en: {
          hours: "Hours",
          date: "Date",
          monday: "Mon",
          tuesday: "Tue",
          wednesday: "Wed",
          thursday: "Thu",
          friday: "Fri",
          saturday: "Sat",
          sunday: "Sun"
        },
        dk: {
          hours: "Timer",
          date: "Dato",
          monday: "Man",
          tuesday: "Tir",
          wednesday: "Ons",
          thursday: "Tor",
          friday: "Fre",
          saturday: "Lør",
          sunday: "Søn"
        }
      }
    });
    const instanceId = getCurrentInstance().uid;

    onMounted(() => {
      let data = [];
      const xValue = d => d.x;
      const yValue = d => d.y;
      const margin = isMobile()
        ? { top: 35, right: 20, bottom: 50, left: 30 }
        : { top: 50, right: 20, bottom: 50, left: 30 };
      const chartContainer = d3.select("#chart" + instanceId);
      const width = +chartContainer.node().getBoundingClientRect().width;
      const height = +chartContainer.node().getBoundingClientRect().height;
      const innerWidth = width - margin.right - margin.left;
      const innerHeight = height - margin.top - margin.bottom;
      const xScale = d3.scaleBand();
      const yScale = d3.scaleLinear();
      const xAxisCall = d3.axisBottom();
      const yAxisCall = d3.axisLeft();

      const initAxis = (dataContainer, innerHeight) => {
        dataContainer
          .append("g")
          .attr("class", "y axis")
          .call(yAxisCall);

        dataContainer
          .append("g")
          .attr("class", "x axis")
          .call(xAxisCall)
          .attr("transform", `translate(0, ${innerHeight})`);

        dataContainer
          .select(".x")
          .append("text")
          .attr("class", "axis-label")
          .attr("y", 40)
          .attr("x", innerWidth / 2)
          .text(t("date"));

        dataContainer
          .select(".y")
          .append("text")
          .attr("class", "axis-label")
          .attr("text-anchor", "middle")
          .attr("y", -15)
          .text(t("hours"));
      };

      const setScale = (iW, iH) => {
        xScale.domain(data.map(xValue)).range([0, iW]);

        yScale
          .domain([24, 0])
          .range([0, iH])
          .nice();

        xAxisCall.scale(xScale);
        yAxisCall.scale(yScale);
      };

      const updateAxis = dataContainer => {
        const t = d3.transition().duration(500);

        dataContainer
          .select(".x")
          .transition(t)
          .call(xAxisCall);

        dataContainer.selectAll(".x .domain").remove();
        dataContainer
          .selectAll(".x .tick text")
          .style("display", function(d, i) {
            return i % 5 ? "none" : "initial";
          })
          .attr("transform", `translate(0, 2)`);

        dataContainer
          .select(".y")
          .transition(t)
          .call(yAxisCall);

        dataContainer
          .selectAll(".y .tick text")
          .style("display", function(d, i) {
            return i % 3 ? "none" : "initial";
          });
      };

      const render = (dataContainer, yScale, xScale, iW, iH) => {
        data = [];
        const dates = props.xData.map(d => {
          const days = [
            t("sunday"),
            t("monday"),
            t("tuesday"),
            t("wednesday"),
            t("thursday"),
            t("friday"),
            t("saturday")
          ];
          const date = new Date(d);
          return (
            (isMobile() ? "" : days[date.getDay()]) +
            " " +
            date.getDate() +
            "/" +
            (date.getMonth() + 1)
          );
        });
        for (let i = 0; i < dates.length; i++) {
          data.push({ x: dates[i], y: props.yData[i] });
        }
        if (process.env.NODE_ENV === "development")
          console.log("User chart data:", props.xData, props.yData);

        setScale(iW, iH);

        dataContainer.selectAll("rect").remove();
        const bars = dataContainer.selectAll("rect").data(data);

        const t1 = d3.transition().duration(500);

        const barEnter = bars
          .enter()
          .append("rect")
          .attr("width", 0)
          .attr(
            "transform",
            (d, i) =>
              `translate(${xScale.bandwidth() * i}, ${innerHeight -
                (innerHeight - yScale(yValue(d)))})`
          );

        barEnter
          .merge(bars)
          .attr("height", d => {
            return innerHeight - yScale(yValue(d));
          })
          .attr("width", xScale.bandwidth())
          .attr("fill", "#f5ae00");

        barEnter
          .transition(t1)
          .attr("width", xScale.bandwidth())
          .delay((d, i) => {
            return i * 30;
          });

        bars
          .exit()
          .transition(t1)
          .attr("width", 0)
          .remove();

        updateAxis(dataContainer);
      };

      const dataG = chartContainer
        .append("g")
        .attr("transform", `translate(${margin.left}, ${margin.top})`);

      setScale(innerWidth, innerHeight);
      initAxis(dataG, innerHeight);

      watchEffect(() => {
        if (props.xData !== undefined && props.yData !== undefined)
          render(dataG, yScale, xScale, innerWidth, innerHeight);
      });
    });

    return { instanceId };
  }
};
</script>

<style>
text {
  font-family: "Bicyclette";
  font-size: 0.8rem;
}

.axis-label {
  fill: #009090;
  font-size: 1rem;
  font-weight: 600;
}

.toolTip {
  position: absolute;
  display: none;
  min-width: 80px;
  height: auto;
  background: none repeat scroll 0 0 #ffffff;
  border: 1px solid #6f257f;
  padding: 14px;
  text-align: center;
}
</style>
